// @import './_vars.scss';

.beachwood {
  .menu-navigation {
    max-width: 840px;
    margin: 1em auto;
    padding: 0;
  
    .category-link {
      padding: 0;
      margin: 0;
      // padding-right: 0.5em;
      // color: $red;
      opacity: 0.5;
     
    }
    .link-separator {
      // color: $red;
      opacity: 0.5;
    }
  }
  
}
