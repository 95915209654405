.menu-index-item {
    font-size: 0.8em;
    .index-link-item {
        padding: 1em 0.5em;
        margin: 0.5em;
        display: block;
        border-radius: 0.2em;
        border: 1px solid rgba(100,100,100,0.4);
        text-decoration: none;
        color: black;
        background: rgba(255,255,255,0.5);

        .menu-title {
            font-weight: 700;
            .num-of-items {
                font-weight: 400;
            }
        }
        .description {
            font-size: 0.85em;
        }
    }
    
}