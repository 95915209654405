.urban {
  .category.wine {
    .menu-item {
      flex-direction: row;
      justify-content: center;
      // flex-wrap: wrap;
      flex: 1;
      margin: 0.2em;
      // max-height: 500px;
      // width: 100%;
      box-sizing: border-box;
      align-items: center;
      .content {
        text-align: left;
      }
      .menu-item__description {
        text-align: left;
      }
      // min-width: 150px;

      @media (min-width: 550px) {
        width: auto;
      }
    }
  }
}
