$sigFont: itc-american-typewriter, serif;
// $sigFont: courier, monospace;
$sigFontCompressed: itc-american-typewriter, serif;
$deco: itc-american-typewriter, serif;

.page {
  .header {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      text-align: center;
      margin-left: 1em;
      font-size: 1.5em;
      // text-transform: uppercase;
      font-weight: 900;
      color: white;
      text-shadow: -2px 2px 0 black;
      font-family: $deco;
    }
  }
  .mini-banner {
    max-width: 720px;
    margin: 1em auto;
    img {
      width: 100%;
    }
  }
  .content {
    font-size: 0.9em;
    max-width: 720px;
    margin: 1em auto;
    line-height: 1.4em;
    p {
      margin: 2em auto;
    }

  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    img {
      display: block;
    }
    .item {
      width: 50%;
    }
  }

}
