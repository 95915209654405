
.menu-category-list-view.alt-style-a {

    
        max-width: 1280px;
        padding: 0 0.3em;
    
    a.item-link {
        color: black;
        display: block;
        text-decoration: none;
        &:visited {
            color: black;
        }
    }
    .menu-item {
        
        background: white !important;
        box-shadow: rgb(48 50 59 / 8%) 0px 3px 10px 0px, rgb(0 0 0 / 1%) 0px 1px 0px 0px, rgb(0 0 0 / 1%) 0px 0px 5px 0px;
        padding: 0 !important;
        overflow: hidden;
        align-items: stretch !important;
        // justify-content: center;
        .item-link {
            width: 80%;
        }
        .content {
            padding: 1em 0.8em 1em 1em;
            width: auto !important;
        }
        .photo-container {
            // flex: 1;
           
            padding: 0 !important;
            margin: 0 !important;
            border-radius: none;
            img {
                object-fit: cover;
                display: block;  
                height: 100%;
                width: 100%;
                
            }
        }
    
        .item-price {
            margin-top: 0.5em;
            font-size: 0.8em;
            // button link
            // display: inline-block;
            color: black;
            background: rgba(0,0,0,0.1);
            padding: 0.5em 1em;
            border-radius: 0.3em;
            border: 2px solid rgba(0,0,0,0.1);
            text-decoration: none;
            font-size: 0.8em;
            display: inline-flex;
            align-items: center;
            
            // flex-grow: 0;
    
        }
        .add-to-bag-icon {
            width: 1.5em;
        }
    }
}