:root {
    --blue-color: #115298;
}
// $blue: #115298;
// $gold: #fdba4a;
#root .mpath_house {
    background: none;
    .cart-wrapper, .pages-nav {
        display: none !important;
        // position: inherit;
    }
    .cart.minimized {
        display: block;
        .checkout-button {
            display: none;
        }
    }
    .qr-menu {
        max-width: 800px;
        margin: 0 auto;
        // padding-top: 0.5em;
        padding: 0.5em 0.3em 0 0.3em;
        .menu-item {
            display: block;
        }
        .price {
            margin: 0;
            padding-left: 1em;
            font-size: 0.8em;
        }
        .category-name {
            // text-align: left;
            .text {
                padding: 0
            }
        }
        .category__description {
            text-align: center;
        }
        .category__header {
            position: relative;
            z-index: 1000;
            margin: 1em 0.25em;
            display: flex;
            align-items: center;
            // border-bottom 2px solid 
            .category-name {
                // text-transform: uppercase;
                flex: 1;
                padding-right: 30px; 
            }
        }
        .highlight {
            font-size: 0.9em;
            .highlight-text {
                display: inline-block;
                background-color: rgb(223, 223, 223);
                border-radius: 0.4em;
                padding: 0.3em 0.3em 0 0.3em;
                margin-bottom: 0.4em;
                line-height: 1em;
            }
        }
    }

}